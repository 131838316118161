<template>
  <div id="sleft2">
    <div class="title">招商数据看版</div>
    <div style="width: 100%">
      <div class="box-bac" style="width: 100%; height: 196px; overflow: hide">
        <div id="left21Echart" style="width: 100%; height: 196px" v-if="targetData.length>0"></div>
        <div v-else class="noData" style="width: 100%; height: 196px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {},
  data() {
    return {
      targetData: [],
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.iniChart();
  },
  methods: {
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "floor.intention.get",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          this.targetData = data.map((c, index) => {
            const { week, name,rate } = c;
            return {
              ...c,
              name: name+week,
              value:rate,
            };
          });
          if(this.targetData.length>0){
          let that = this;
            let times = setTimeout(() => {
              that.iniChart();
              clearTimeout(times)
            }, 1000);
          }
        });
    },

    iniChart() {
      const isFont = this.$route.query.type;

      this.chart = this.$echarts.init(document.getElementById("left21Echart"));
      let targetData=this.targetData;
      let that = this;
      this.chart.setOption({
            title: {
              text: "",
            },
            tooltip: {},
            legend: {
              data: ["销量"]
            },
            grid: {
          top:"24",
          left: "10%",
          bottom:"30",
        },
            xAxis: {
              type: "category",
              data: targetData.map(c => c.name),
              axisTick:{
             show:false
           },
              axisLine: {
                show: true,
                lineStyle: {
                    color: "#fff",
                    width: 0,
                    type: "solid"
                }
            },
            },
            yAxis: {
              type: "value",
              splitLine: {show: false},
              axisTick:{
             show:false
           },
              axisLine: {
                show: true,
                lineStyle: {
                    color: "#fff",
                    width: 0,
                    type: "solid"
                }
            },
            },
            series: [
              {
                name: "",
                type: "line",
                itemStyle: {
                  color: "rgba(212, 85, 255, 1)"
                },
                smooth: true,
                symbol:"circle",
                symbolSize:0,
                areaStyle: {
                  color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1.5, [
                    {
                      offset: 0,
                      color: "rgba(212, 85, 255, 1)"
                    },
                    {
                      offset: 1,
                      color: "rgba(255, 255, 255, 0)"
                    }
                  ])
                },
                data: targetData.map(c => c.value)
              }
            ]
          });
    },
    // 字体适配
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      console.log(fontSize);
      return res * fontSize;
    },
    timer() {
      return setInterval(() => {
        this.getList();
      }, 60000);
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getRechartData();
    },
  },
};
</script>

<style lang="scss" scope>
#sleft2 {
  width: 916px;
  height: 224px;
  color: #ffffff;
  .title {
    height: 32px;
    line-height: 32px;
    padding-left: 16px;
    color: #a6e8ff;
    font-size: 18px;
    background: url("../../../assets/bg_title.png") no-repeat left;
    background-size: 320px 32px;
  }
  .noData{
    background: url("../../../assets/default.png") no-repeat center center;
    background-size: 180px 100px;
    text-align: center;
  }
}
</style>
