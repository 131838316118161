<template>
  <div id="sleft1">
    <div
      id="left14Echart"
      style="width: 100%; height: 100%"
      v-if="targetData.length > 0"
    ></div>
    <div v-else class="noData" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {},
  data() {
    return {
      targetData: [],
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.iniChart();
  },
  methods: {
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "ticket.time.get",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          this.targetData = data;
          if (this.targetData.length > 0) {
            let that = this;
            let times = setTimeout(() => {
              that.iniChart();
              clearTimeout(times);
            }, 1000);
          }
        });
    },

    iniChart() {
      const isFont = this.$route.query.type;
      this.chart = this.$echarts.init(document.getElementById("left14Echart"));
      let pickData = this.targetData;
      let that = this;
      this.chart.setOption({
        title: {
          show: false,
        },
        grid: {
          top: "24",
          left: "10%",
          bottom: "40",
        },
        xAxis: {
          type: "category",
          // axisLabel: { interval: 0, rotate: 30 },
          data: pickData.map((c) => c.name),
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              width: 0,
              type: "solid",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: { show: false },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              width: 0,
              type: "solid",
            },
          },
        },
        series: [
          {
            data: pickData.map((c) => c.rate),
            type: "bar",
            barWidth: 25,
            itemStyle: {
              normal: {
                //这里是重点
                color: function (params) {
                  //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                  var colorList = [
                    ["#D9FD88", "#74FF9F"],
                    ["#9DD0FF", "#544AFF"],
                    ["#FFEA6D", "#FFA06E"],
                    ["#FF8E8E", "#F70059"],
                  ];
                  var colorItem = colorList[params.dataIndex];
                  return new that.$echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: colorItem[0],
                      },
                      {
                        offset: 1,
                        color: colorItem[1],
                      },
                    ],
                    false
                  );
                },
              },
            },
          },
        ],
      });
    },
    // 字体适配
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      console.log(fontSize);
      return res * fontSize;
    },
    timer() {
      return setInterval(() => {
        this.getList();
      }, 60000);
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getRechartData();
    },
  },
};
</script>

<style lang="scss" scoped>
#sleft1 {
  width: 458px;
  height: 210px;
  //   color: #ffffff;
  //   .box-bac{
  //     background: url("../../../assets/bg09.png") no-repeat;
  //     background-size: 100% 100%;
  //   }
  //   .title {
  //     height: 32px;
  //     line-height: 32px;
  //     padding-left: 16px;
  //     color: #a6e8ff;
  //     font-size: 18px;
  //     background: url("../../../assets/bg_title.png") no-repeat left;
  //     background-size: 160px 32px;
  //   }
  .noData {
    background: url("../../../assets/default.png") no-repeat center center;
    background-size: 180px 100px;
  }
}
</style>
