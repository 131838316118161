<template>
  <div id="sleft2">
    <div class="title">招商进度分析</div>
    <div style="width: 100%">
      <div class="box-bac" style="width: 100%; height: 192px; overflow: hide">
        <div id="left22Echart" style="width: 100%; height: 192px" v-if="targetData.length>0"></div>
        <div v-else class="noData" style="width: 100%; height: 192px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {},
  data() {
    return {
      targetData: [],
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.iniChart();
  },
  methods: {
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "floor.market.get",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          this.targetData = data;
          if(this.targetData.length>0){
          let that = this;
            let times = setTimeout(() => {
              that.iniChart();
              clearTimeout(times)
            }, 1000);
          }
        });
    },

    iniChart() {
      const isFont = this.$route.query.type;

      this.chart = this.$echarts.init(document.getElementById("left22Echart"));
      let that=this;
      this.chart.setOption({
          tooltip: {
            trigger: "axis"
          },
          grid: {
          top:"24",
          left: "10%",
          bottom:"30",
        },
          legend: {
            data: ["意向人数", "已出租","空置"],
            right:"48",
            textStyle:{
              color:"#ffffff"
            }
          },
          calculable: true,
          xAxis: [
            {
              data: this.targetData.map(ele => ele.aka.slice(0,4)),
              axisLabel: {
                rotate:25,
                width: 100
              },
              axisTick:{
             alignWithLabel: true,
           },
              axisLine: {
                show: true,
                lineStyle: {
                    color: "#fff",
                    width: 0,
                    type: "solid"
                }
            },
            }
          ],
          yAxis: [
            {
              type: "value",
              splitLine: {show: false},
              axisTick:{
             show:false
           },
              axisLine: {
                show: true,
                lineStyle: {
                    color: "#fff",
                    width: 0,
                    type: "solid"
                }
            },
            }
          ],
          series: [
            {
              name: "意向人数",
              type: "bar",
              data: this.targetData.map(ele => ele.intention_num),
              // barWidth: 32,
              label: {
                show: false,
              },
              itemStyle: {
                    barBorderRadius: [2, 2, 0, 0], //柱体圆角   
                    color: new that.$echarts.graphic.LinearGradient(
                        //前四个参数用于配置渐变色的起止位置，这四个参数依次对应 右下左上 四个方位。也就是从右边开始顺时针方向。
                        //通过修改前4个参数，可以实现不同的渐变方向
                        /*第五个参数则是一个数组，用于配置颜色的渐变过程。
                          每项为一个对象，包含offset和color两个参数
                        */
                        0, 0, 0, 1, [{//代表渐变色从正上方开始
                                offset: 0, //offset范围是0~1，用于表示位置，0是指0%处的颜色
                                color: '#D9FD88'
                            }, //柱图渐变色
                            {
                                offset: 1, //指100%处的颜色
                                color: '#74FF9F'
                            }
                        ]
                    ),
                }
            },
            {
              name: "已出租",
              type: "bar",
              data: this.targetData.map(ele => ele.leased_num),
              // barWidth: 32,
              label: {
                show: false,
              },
              itemStyle: {
                    barBorderRadius: [2, 2, 0, 0], //柱体圆角   
                    color: new that.$echarts.graphic.LinearGradient(
                        //前四个参数用于配置渐变色的起止位置，这四个参数依次对应 右下左上 四个方位。也就是从右边开始顺时针方向。
                        //通过修改前4个参数，可以实现不同的渐变方向
                        /*第五个参数则是一个数组，用于配置颜色的渐变过程。
                          每项为一个对象，包含offset和color两个参数
                        */
                        0, 0, 0, 1, [{//代表渐变色从正上方开始
                                offset: 0, //offset范围是0~1，用于表示位置，0是指0%处的颜色
                                color: '#FFEA4A'
                            }, //柱图渐变色
                            {
                                offset: 1, //指100%处的颜色
                                color: '#FF870B'
                            }
                        ]
                    ),
                }
            },
            {
              name: "空置",
              type: "bar",
              data: this.targetData.map(ele => ele.vacancy_num),
              // barWidth: 32,
              label: {
                show: false,
              },
              itemStyle: {
                    barBorderRadius: [2, 2, 0, 0], //柱体圆角   
                    color: new that.$echarts.graphic.LinearGradient(
                        //前四个参数用于配置渐变色的起止位置，这四个参数依次对应 右下左上 四个方位。也就是从右边开始顺时针方向。
                        //通过修改前4个参数，可以实现不同的渐变方向
                        /*第五个参数则是一个数组，用于配置颜色的渐变过程。
                          每项为一个对象，包含offset和color两个参数
                        */
                        0, 0, 0, 1, [{//代表渐变色从正上方开始
                                offset: 0, //offset范围是0~1，用于表示位置，0是指0%处的颜色
                                color: '#FF8EE3'
                            }, //柱图渐变色
                            {
                                offset: 1, //指100%处的颜色
                                color: '#F1065B'
                            }
                        ]
                    ),
                }
            }
          ]
        });
    },
    // 字体适配
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      console.log(fontSize);
      return res * fontSize;
    },
    timer() {
      return setInterval(() => {
        this.getList();
      }, 60000);
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getRechartData();
    },
  },
};
</script>

<style lang="scss" scope>
#sleft2 {
  width: 916px;
  height: 228px;
  color: #ffffff;
  .title {
    height: 32px;
    line-height: 32px;
    padding-left: 16px;
    color: #a6e8ff;
    font-size: 18px;
    background: url("../../../assets/bg_title.png") no-repeat left;
    background-size: 320px 32px;
  }
  .noData{
    background: url("../../../assets/default.png") no-repeat center center;
    background-size: 180px 100px;
    text-align: center;
  }
}
</style>
