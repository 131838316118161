<template>
  <div id="sleft1">
    <div
      id="left3Echart"
      style="width: 100%; height: 100%"
      v-if="targetData.length > 0"
    ></div>
    <div v-else class="noData" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {},
  data() {
    return {
      targetData: [],
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.iniChart();
  },
  methods: {
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "ticket.cate.get",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          if (data && data.length > 0) {
            this.targetData = data.map((c, index) => {
              const { rate, name } = c;
              return {
                ...c,
                name: name,
                value: rate,
              };
            });
          }
          if (this.targetData.length > 0) {
            let that = this;
            let times = setTimeout(() => {
              that.iniChart();
              clearTimeout(times);
            }, 1000);
          }
        });
    },

    iniChart() {
      const isFont = this.$route.query.type;

      this.chart = this.$echarts.init(document.getElementById("left3Echart"));
      this.chart.setOption({
        title: {
          text: "",
        },
        legend: {
          orient: "vertical",
          y: "center", //延Y轴居中
          right: 10,
          itemWidth:8,
          itemHeight:8,
          textStyle: {
            color: "#fff",
          },
        },
        color: ["#FF4B4B", "#FF9629", "#FEE501", "#50C84A", "#55EFB1"],
        series: [
          {
            type: "pie",
            center: ["40%", "50%"],
            roseType: 'area',
            data: this.targetData,
            label: {
              normal: {
                textStyle: {
                  fontSize: this.FontChart(0.15),
                  color: "#ffffff",
                  fontWeight: 400,
                },
                formatter: "{c}%",
              },
              x: 0,
            },
            labelLine: {
              length: 6,
            },
          },
        ],
      });
    },
    // 字体适配
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      console.log(fontSize);
      return res * fontSize;
    },
    timer() {
      return setInterval(() => {
        this.getList();
      }, 60000);
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getRechartData();
    },
  },
};
</script>

<style lang="scss" scoped>
#sleft1 {
  width: 458px;
  height: 210px;
  //   color: #ffffff;
  //   .box-bac{
  //     background: url("../../../assets/bg09.png") no-repeat;
  //     background-size: 100% 100%;
  //   }
  //   .title {
  //     height: 32px;
  //     line-height: 32px;
  //     padding-left: 16px;
  //     color: #a6e8ff;
  //     font-size: 18px;
  //     background: url("../../../assets/bg_title.png") no-repeat left;
  //     background-size: 160px 32px;
  //   }
  .noData {
    background: url("../../../assets/default.png") no-repeat center center;
    background-size: 180px 100px;
  }
}
</style>
