<template>
  <div id="index1">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center" style="margin-bottom: 24px">
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 100%;
              align-items: center;
              padding: 0 0.25rem;
            "
          >
            <div style="width: 8rem; display: flex; align-items: center">
              <span
                style="
                  font-size: 0.3rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #ffffff;
                  margin-right: 0.05rem;
                "
                >{{ integreteData.weather }}</span
              >
            </div>
            <div class="top_head">{{ integreteData.title }}</div>

            <div class="text" :class="{ weekStyle: $route.query.type == 2 }">
              {{ dateYear }} {{ dateWeek }} {{ dateDay }}
            </div>
          </div>
        </div>

        <div class="body-box">
          <!-- 主体部分，分为左中右三部分，左边又分为左上和左下，中间分为中上和中下，右边分为右上右中右下 -->
          <div
            class="content-box d-flex"
            style="width: 100%; justify-content: space-between"
          >
            <!-- 左边 -->
            <div class="leftFirst" style="width: 916px; height: 452px">
              <div class="" style="margin-bottom: 16px">
                <div class="in_title">巡检工单数据看版</div>
                <div class="box-bac">
                  <!--巡检工单数据看版-->
                  <div class="d-flex" style="justify-content: space-between">
                    <div class="width:458px;height: 210px;">
                      <sleft11 />
                    </div>
                    <div class="width:458px;height: 210px;">
                      <sleft12 />
                    </div>
                  </div>
                  <div class="blank"></div>
                  <div class="d-flex" style="justify-content: space-between">
                    <sleft13 />
                    <sleft14 />
                  </div>
                </div>
              </div>
              <div>
                <!-- 招商数据看版 -->
                <div class="box-bac">
                  <div class="width:916px;height: 224px;">
                    <sleft21 />
                  </div>
                  <div class="width:916px;height: 224px;">
                    <sleft22 />
                  </div>
                </div>
              </div>
            </div>
            <!-- 右边 -->
            <div class="rightFirst" style="width: 916px">
              <div style="margin-bottom: 16px">
                <!-- 总商户统计数 -->
                <div class="in_title">财务缴费数据看版</div>
                <div class="box-bac">
                  <div class="d-flex" style="justify-content: space-between">
                    <sright11></sright11>
                    <sright12></sright12>
                  </div>
                </div>
              </div>
              <div>
                <div class="box-bac">
                  <div class="d-flex" style="justify-content: space-between">
                    <sright21></sright21>
                    <sright22></sright22>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";
import sleft11 from "./components/sleft11";
import sleft12 from "./components/sleft12";
import sleft13 from "./components/sleft13";
import sleft14 from "./components/sleft14";
import sleft21 from "./components/sleft21";
import sleft22 from "./components/sleft22";
import { getUserIP } from "@/utils/ip";
import sright11 from "./components/sright11";
import sright12 from "./components/sright12";
import sright21 from "./components/sright21";
import sright22 from "./components/sright22";
export default {
  data() {
    return {
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      global_data: {}, //全局数据
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      market_name: "",
      uuid: "",
      type: "",
      notice: "",
      sale_money: "",
      sale_num: "",
      merchant_num: "",
      flow_num: "",
      mouth_quality_num: "",
      inspection_num: "",
      qualified_rate: "",
      show: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度
      ip: "",
      local: {
        // ip地址所在城市
        city: "",
        // 所在城市温度
        temperature: "",
        // 天气类型
        type: "",
        province: "",
      },
      debug_city: "",
      integreteData: {},
      ip: "",
    };
  },
  components: {
    sleft11,
    sleft12,
    sleft13,
    sleft14,
    sleft21,
    sleft22,
    sright11,
    sright12,
    sright21,
    sright22,
  },
  mounted() {
    if(!sessionStorage.getItem("ip")){
    this.getIp();
    }else{
      this.init();
    }
    this.timeFn();
    this.cancelLoading();
    setInterval(() => {
      this.$router.go(0);
    }, 300000);
  },
  methods: {
    getIp(){
      const getIPs = (callback) => {
      var ip_dups = {};
      var RTCPeerConnection =
        window.RTCPeerConnection ||
        window.mozRTCPeerConnection ||
        window.webkitRTCPeerConnection;
      var useWebKit = !!window.webkitRTCPeerConnection;
      var mediaConstraints = {
        optional: [{ RtpDataChannels: true }],
      };
      // 这里就是需要的ICEServer了
      var servers = {
        iceServers: [
          { urls: "stun:stun.services.mozilla.com" },
          { urls: "stun:stun.l.google.com:19302" },
        ],
      };
      var pc = new RTCPeerConnection(servers, mediaConstraints);
      function handleCandidate(candidate) {
        var ip_regex =
          /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/;
        var hasIp = ip_regex.exec(candidate);
        if (hasIp) {
          var ip_addr = ip_regex.exec(candidate)[1];
          if (ip_dups[ip_addr] === undefined) callback(ip_addr);
          ip_dups[ip_addr] = true;
        }
      }
      // 网络协商的过程
      pc.onicecandidate = function (ice) {
        if (ice.candidate) {
          handleCandidate(ice.candidate.candidate);
        }
      };
      pc.createDataChannel("");
      //创建一个SDP(session description protocol)会话描述协议 是一个纯文本信息 包含了媒体和网络协商的信息
      pc.createOffer(
        function (result) {
          pc.setLocalDescription(
            result,
            function () {},
            function () {}
          );
        },
        function () {}
      );
      setTimeout(function () {
        var lines = pc.localDescription.sdp.split("\n");
        lines.forEach(function (line) {
          if (line.indexOf("a=candidate:") === 0) handleCandidate(line);
        });
      }, 1000);
    };
    let that = this;
    getIPs((ip) => {
      console.log(ip);
    sessionStorage.setItem("ip", ip);
      that.ip = ip;
      console.log(that.ip);
      that.init();
    });
    },
    timeFn() {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
        this.type = qs["type"];
      } else {
        this.uuid = this.$route.query.uuid;
        this.type = this.$route.query.type;
      }
      this.getMarketTitle();
    },
    async topInit() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
    },

    getMarketTitle() {
      axios
        .post("api/sscreen/client/main", {
          action: "sscreen.init",
          uuid: this.uuid,
          ip: this.ip,
          // uuid: "f8a9737f-edf7-3fd2-bc61-5318ca8705ff",
        })
        .then((res) => {
          console.log(res);
          let data = res.data;
          if (data.code == 0) {
            this.integreteData = data.data;
            console.log(this.integreteData);
          }
        });
    },
    link() {
      this.show = !this.show;
    },
    changeScreen() {
      this.$router.push({ name: "index" });
    },
    //执行每天24:00 刷新
    refreshWindow() {
      var nowTemp = new Date().getTime(); //获取当前时间戳
      var tomorrowTemp =
        new Date(new Date().toLocaleDateString()).getTime() +
        24 * 60 * 60 * 1000;
      var residueTemp = tomorrowTemp - nowTemp; //距离当天24：00的时间戳
      //执行定时任务
      setTimeout(() => {
        this.nowData();
      }, residueTemp);
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/assets/scss/index1.scss";
@import "../../assets/font/font.css";
.bg {
  padding: 0 36px;
  background: #0b054a;
  background-size: cover;
  background-position: center center;
}
.flex-center-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-center-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.host-body {
  background: url("../../assets/bg_top.png") no-repeat;
  background-size: 100%;

  .top_head {
    line-height: 66px;
    text-align: center;
    width: 100%;
    height: 90px;
    font-size: 48px;
    font-weight: normal;
    font-family: PingFang SC-Semibold;
    font-weight: bold;
    color: #64D6FF;
    letter-spacing: 0.2rem;
  }
  .text {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    width: 8rem;
    text-align: right;
  }
  .weekStyle {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    width: 640px;
    text-align: right;
  }
}
.box-bac {
  background: url("./../../assets/bg09.png") no-repeat;
  background-size: 100% 100%;
}
.in_title {
  height: 32px;
  line-height: 32px;
  padding-left: 16px;
  color: #a6e8ff;
  font-size: 18px;
  background: url("./../../assets/bg_title.png") no-repeat left;
  background-size: 320px 32px;
}
.blank{
  width: 836px;
  height: 2px;
  margin: 0 auto;
  background: linear-gradient(180deg, #17008E 0%, rgba(8,7,89,0) 100%);;
}
</style>