<template>
  <div id="sleft2">
    <div class="title" style="margin-bottom: 14px">投诉评价数据看版</div>
    <div style="width: 100%">
      <div
        class="box-bac"
        style="
          width: 422px;
          height: 98px;
          overflow: hide;
          margin: 0 auto;
          margin-bottom: 8px;
        "
      >
        <div class="d-flex stasiList" style="justify-content: space-between;border-bottom:1px solid #10197F;">
          <div class="d-flex" style="border-right:1px solid rgb(16, 25, 127);">
            <div class="">评价满意（人）</div>
            <div class="number">{{statisData.my}}</div>
          </div>
          <div
            class="d-flex"
          >
            <div>评价一般（人）</div>
            <div class="number">{{statisData.yb}}</div>
          </div>
        </div>
        <div class="d-flex stasiList" style="justify-content: space-between;border-bottom:1px solid #10197F;">
          <div
            class="d-flex"
             style="border-right:1px solid rgb(16, 25, 127);"
          >
            <div>评价不满意（人）</div>
            <div class="number">{{statisData.bmy}}</div>
          </div>
          <div
            class="d-flex"
          >
            <div>评价非常不满意（人）</div>
            <div class="number">{{statisData.fcbmy}}</div>
          </div>
        </div>
      </div>
      <div
        class="box-bac"
        style="
          width: 422px;
          height: 98px;
          overflow: hide;
          margin: 0 auto;
          margin-bottom: 8px;
        "
      >
      <div class="d-flex stasiList" style="justify-content: space-between;border-bottom:1px solid #10197F;">
          <div class="d-flex">
            <div class="">商品投诉</div>
            <div class="number">{{statisData.sp}}</div>
          </div>
          <div
            class="d-flex"
          >
            <div>价格投诉</div>
            <div class="number">{{statisData.jg}}</div>
          </div>
        </div>
        <div class="d-flex stasiList" style="justify-content: space-between;border-bottom:1px solid #10197F;">
          <div
            class="d-flex"
          >
            <div>服务投诉</div>
            <div class="number">{{statisData.fw}}</div>
          </div>
          <div
            class="d-flex"
          >
            <div>环境投诉</div>
            <div class="number">{{statisData.hj}}</div>
          </div>
        </div>
      </div>
      <div
        class="box-bac"
        style="width: 422px; height: 180px; overflow: hide; margin: 0 auto"
      >
        <dv-scroll-board
          :config="config"
          v-if="targetData.length > 0"
          style="width: 422px; height: 180px; margin: 0 auto"
        />
        <div v-else class="noData" style="width: 100%; height: 180px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {},
  data() {
    return {
      config: {},
      targetData: [],
      statisData:{},
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.iniChart();
  },
  methods: {
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "evaluate.stat.list",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          this.statisData=data;
        });
    },

    // 获取表格的数据
    getTableList() {
      axios
        .post("api/sscreen/order/report", {
          action: "evaluate.list",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          if (data.length > 0) {
            const target = data.map((c,index) => {
              return {
              index:index+1,
              created_at: formatTime(
                c.created_at * 1000,
                "yyyy-MM-dd"
              ),
              title:c.name+c.mode_name+c.type_name,
              };
            });
            console.log(target)
            this.targetData = target;
            this.config.data = target;
            // let tabHead = ["市场名称", "商品", "单价", "数量", "小计"];
            this.config = {
              data: target,
              rowNum: 5, //表格行数
              headerBGC: "rgb(4, 7, 138, 0)", //表头
              oddRowBGC: "rgba(50, 46, 173, 0)", //奇数行
              evenRowBGC: "rgba(50, 46, 173, 0.09)", //偶数行
              align: ["center", "center", "right"],
            };
          }
        });
    },
    // 字体适配
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      console.log(fontSize);
      return res * fontSize;
    },
    timer() {
      return setInterval(() => {
        this.init();
      }, 60000);
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getRechartData();
      this.getTableList();
    },
  },
};
</script>

<style lang="scss" scoped>
#sleft2 {
  width: 458px;
  height: 452px;
  color: #ffffff;
  .title {
    height: 32px;
    line-height: 32px;
    padding-left: 16px;
    color: #a6e8ff;
    font-size: 18px;
    background: url("../../../assets/bg_title.png") no-repeat left;
    background-size: 320px 32px;
  }
  .box-bac {
    background: url("../../../assets/bg09.png") no-repeat;
    background-size: 100% 100%;
  }
  .stasiList {
    height: 48px;
    line-height: 48px;
    .d-flex {
      width: 50%;
      padding-left: 8px;
      padding-right: 8px;
      justify-content: space-between;
      align-items: center;
    .number{
      display: inline-block;
      height: 32px;
      line-height: 32px;
      padding: 0 8px;
      font-size: 24px;
      font-weight: 500;
      background:linear-gradient(180deg, #5C43E0 0%, #1300AC 100%);
    }
    }
  }
  .noData {
    background: url("../../../assets/default.png") no-repeat center center;
    background-size: 180px 100px;
  }
}
</style>
