<template>
  <div id="sleft1">
      <div class="box-bac" style="width: 100%; height: 420px; overflow: hide">
        <div class="bac_title">费用类型占比</div>
        <div id="right11Echart" style="width: 100%; height: 356px" v-if="targetData.length>0"></div>
        <div v-else class="noData" style="width: 100%; height: 356px"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {},
  data() {
    return {
      targetData: [],
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.iniChart();
  },
  methods: {
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "cost.cate.get",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          this.targetData = data.map((c) => {
            const { rate, name } = c;
            return {
              ...c,
              name: name,
                value: rate,
            };
          });
          if(this.targetData.length>0){
          let that = this;
            let times = setTimeout(() => {
              that.iniChart();
              clearTimeout(times)
            }, 1000);
          }
        });
    },

    iniChart() {
      const isFont = this.$route.query.type;

      this.chart = this.$echarts.init(document.getElementById("right11Echart"));
      this.chart.setOption({
        color: [
          "#4CC529",
          "#FFF169",
          "#FFAF44",
          "#EA6B37",
          "#BB2323",
          "#1DA0FF",
          "#1B4DFF",
          "#6A59FF",
          "#C638FF",
          "#E679C8",
          "#52F0E3",
        ],
        tooltip: {
          trigger: "item",
        },
        legend: {
          x: "center", //延Y轴居中
          bottom: 10,
          itemWidth:8,
          itemHeight:8,
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            type: "pie",
            data: this.targetData,
            center: ["50%", "50%"],
            radius: ["40%", "70%"],
            label: {
              normal: {
                textStyle: {
                  fontSize: this.FontChart(0.16),
                  color: "#ffffff",
                  fontWeight: 400,
                },
                formatter: "{c}%",
              },
              x: 0,
            },
            labelLine: {
              length: 6,
            },
          },
        ],
      });
    },
    // 字体适配
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      console.log(fontSize);
      return res * fontSize;
    },
    timer() {
      return setInterval(() => {
        this.getList();
      }, 60000);
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getRechartData();
    },
  },
};
</script>

<style lang="scss" scoped>
#sleft1 {
  width: 458px;
  height: 420px;
  color: #ffffff;
  .bac_title{
    text-align:center;
    width:160px;
    height:32px;
    line-height:32px;
    margin:32px auto 0;
    font-size: 18px;
    color: #A6E8FF;
    background: url('../../../assets/bg09.png') no-repeat;
    background-size: 100% 100%;
  }
  .title {
    height: 32px;
    line-height: 32px;
    padding-left: 16px;
    color: #a6e8ff;
    font-size: 18px;
    background: url("../../../assets/bg_title.png") no-repeat left;
    background-size: 160px 32px;
  }
  .noData{
    background: url("../../../assets/default.png") no-repeat center center;
    background-size: 180px 100px;
  }
}
</style>
