<template>
  <div id="sleft2">
    <div class="title" style="margin-bottom: 14px">线上平台数据看版</div>
    <div style="width: 100%">
      <div
        class="box-bac"
        style="
          width: 422px;
          height: 98px;
          overflow: hide;
          margin: 0 auto;
          margin-bottom: 8px;
        "
      >
        <div
          class="d-flex stasiList"
          style="
            justify-content: space-between;
            border-bottom: 1px solid #10197f;
          "
        >
          <div class="d-flex" style="border-right:1px solid rgb(16, 25, 127);">
            <div class="">新增会员数</div>
            <div class="number">{{ lineData.new_member_num }}</div>
          </div>
          <div class="d-flex">
            <div>下单量</div>
            <div class="number">{{ lineData.order_num }}</div>
          </div>
        </div>
        <div
          class="d-flex stasiList"
          style="
            justify-content: space-between;
            border-bottom: 1px solid #10197f;
          "
        >
          <div class="d-flex" style="border-right:1px solid rgb(16, 25, 127);">
            <div>成交金额</div>
            <div class="number">￥{{ lineData.order_amount }}{{lineData.unit}}</div>
          </div>
          <div class="d-flex">
            <div>转化率</div>
            <div class="number">{{ lineData.rate }}</div>
          </div>
        </div>
      </div>
      <div
        class="box-bac"
        style="width: 422px; height: 284px; overflow: hide; margin: 0 auto"
      >
        <div
          id="right22Echart"
          style="width: 100%; height: 284px"
          v-if="targetData.length > 0"
        ></div>
        <div v-else class="noData" style="width: 100%; height: 284px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {},
  data() {
    return {
      lineData: {}, //线上数据
      targetData: [], //线
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.iniChart();
  },
  methods: {
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "order.stat.get",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          this.lineData = data;
          this.targetData = data.list;
          if (this.targetData.length > 0) {
            let that = this;
            let times = setTimeout(() => {
              that.iniChart();
              clearTimeout(times);
            }, 1000);
          }
        });
    },

    iniChart() {
      const isFont = this.$route.query.type;
      this.chart = this.$echarts.init(document.getElementById("right22Echart"));
      let targetData = this.targetData;
      let unit = this.targetData[0].unit?this.targetData[0].unit:'';
      console.log(unit);
      let that = this;
      this.chart.setOption({
        title: {
          text: "",
        },
        tooltip: {},
        legend: {
          data: ["订单笔数", "订单金额"],
          top:'20',
          textStyle: {
            color: "#fff",
            fontSize: 10,
          },
        },
        xAxis: {
          type: "category",
          data: targetData.map((c) => c.date),
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              width: 0,
              type: "solid",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
            position: "right",
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#fff",
                width: 0,
                type: "solid",
              },
            },
          },
          {
            type: "value",
            splitLine: { show: false },
            position: "left",
            axisTick: {
              show: false,
            },
            axisLabel: {
              formatter: function (value) {
                return value + unit;
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#fff",
                width: 0,
                type: "solid",
              },
            },
          }
        ],
        series: [
          {
            name: "订单金额",
            type: "line",
            itemStyle: {
              color: "#55FFF0",
            },
            smooth: true,
            symbol: "circle",
            symbolSize: 0,
            data: targetData.map((c) => c.order_amount),
            yAxisIndex:1,
          },
          {
            name: "订单笔数",
            type: "line",
            itemStyle: {
              color: "#FF9A55",
            },
            smooth: true,
            symbol: "circle",
            symbolSize: 0,
            data: targetData.map((c) => c.order_num),
            yAxisIndex:0,
          },
          
        ],
      });
    },
    // 字体适配
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      console.log(fontSize);
      return res * fontSize;
    },
    timer() {
      return setInterval(() => {
        this.getList();
      }, 60000);
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getRechartData();
    },
  },
};
</script>

<style lang="scss" scoped>
#sleft2 {
  width: 458px;
  height: 452px;
  color: #ffffff;
  border-left: 1px solid #000d9a;
  .title {
    height: 32px;
    line-height: 32px;
    padding-left: 16px;
    color: #a6e8ff;
    font-size: 18px;
    background: url("../../../assets/bg_title.png") no-repeat left;
    background-size: 320px 32px;
  }
  .box-bac {
    background: url("../../../assets/bg09.png") no-repeat;
    background-size: 100% 100%;
  }
  .stasiList {
    height: 48px;
    line-height: 48px;
    .d-flex {
      width: 50%;
      padding-left: 8px;
      padding-right: 8px;
      justify-content: space-between;
      align-items: center;
      .number {
        display: inline-block;
        height: 32px;
        line-height: 32px;
        padding: 0 8px;
        font-size: 24px;
        font-weight: 500;
        background: linear-gradient(180deg, #5c43e0 0%, #1300ac 100%);
      }
    }
  }
  .noData {
    background: url("../../../assets/default.png") no-repeat center center;
    background-size: 180px 100px;
  }
}
</style>
